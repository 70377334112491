import React, { useState, useEffect } from "react";
import { motion as m } from "framer-motion";

const Toast = ({ message, isVisible }) => {
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => {
    setVisible(isVisible);
  }, [isVisible]);

  return (
    visible && (
      <m.div
        className="flex px-[24px] py-[16px] absolute z-50 lg:left-[48px] lg:bottom-[48px] md:left-[32px] md:bottom-[32px] bottom-[24px] bg-[#C3FFF1] border-[2px] border-black shadow-[2px_2px_rgba(0,0,0,255)] rounded-[12px]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <p>{message}</p>
      </m.div>
    )
  );
};

export default Toast;
