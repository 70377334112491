import { useState, useEffect } from "react";
import CountdownTimer from "../utils/CountdownTimer";
import { Icon } from "@iconify/react";
import WidgetBot from "@widgetbot/react-embed";
import "./Home.css";
import { initializeApp } from "firebase/app";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import PubNub from "pubnub";
import { v4 as uuidv4 } from "uuid";
import Toast from "./toast";
import BackgroundClickables from "./BackgroundClickables";

const uuid = uuidv4();

// TODO: CHANGE THIS PART ACCORDING TO YOUR FIREBASE CONFIGURATION
const firebaseConfig = {
  apiKey: "AIzaSyC5LyGrP17PxS_QPcd06_y22j2p10OMOAY",
  authDomain: "testing-react-firebase33275.firebaseapp.com",
  projectId: "testing-react-firebase33275",
  storageBucket: "testing-react-firebase33275.appspot.com",
  messagingSenderId: "117146621592",
  appId: "1:117146621592:web:4f051cc43a2e70548962ed",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const pubnub = new PubNub({
  publishKey: "pub-c-95870a99-2df9-4818-8091-665820fb5532",
  subscribeKey: "sub-c-dfd7d568-d41a-4b51-b004-ba7194953744",
  uuid: uuid,
});

const Home = () => {
  const [discordChatEnabled, setDiscordChatEnabled] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [api, setApi] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [uName, setUName] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [currentBackground, setCurrentBackground] = useState("");
  const [videoPath, setVideoPath] = useState("");
  const date = new Date("2024-04-20T04:00:00");
  const currentChannel = "Default";

  const fetchData = async (username) => {
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("username", "==", username));
    const querySnapshot = await getDocs(q);
    let vid = "";
    querySnapshot.forEach((doc) => {
      let temprole = doc.data().roles;
      if (temprole.includes(";")) {
        temprole = temprole.split(";");
        vid = `/videos/${temprole[0]}.webm`;
      } else {
        vid = `/videos/${temprole}.webm`;
      }
    });
    return vid;
  };

  const handleCoinClick = () => {
    if (api && localStorage.getItem("username")) {
      api.emit("sendMessage", "I clicked a coin!");
    }
  };

  const handleFrogClick = () => {
    if (api && localStorage.getItem("username")) {
      api.emit("sendMessage", "I clicked a frog!");
    }
  };

  const onAPI = (api) => {
    setApi(api);
    api.on("signIn", async (user) => {
      localStorage.setItem("username", `${user.username}`);
      setIsConnected(true);
      const videoPath = await fetchData(user.username);
      setUName(() => user.username);
      setToastMessage(`${user.username} just logged in!`);
      setToastVisible(true);
      setVideoPath(videoPath);
      setShowVideo(true);
      pubnub.publish({
        channel: currentChannel,
        message: {
          username: user.username,
          visibleToast: true,
          vidPath: videoPath,
        },
      });
    });
    api.on("signOut", () => {
      if (discordChatEnabled && localStorage.getItem("username")) {
        localStorage.removeItem("username");
        setDiscordChatEnabled(false);
        setIsConnected(false);
        setUName("");
      }
    });
  };

  const tweet = () => {
    const countdownDate = new Date(date).getTime();
    const now = new Date().getTime();
    const distance = countdownDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const text = encodeURIComponent(
      `${days} days until halving!🪙🐸 #BitcoinHalving https://ribbitsticker.com/pages/bitcoin-halving`
    );
    const url = `https://twitter.com/intent/tweet?text=${text}`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setCurrentBackground("/images/background/background-mobile.png");
      } else if (window.innerWidth < 1024) {
        setCurrentBackground("/images/background/background-tablet.png");
      } else {
        setCurrentBackground("/images/background/background-desktop.png");
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("username")) {
      setIsConnected(true);
    }
  }, [isConnected]);

  useEffect(() => {
    pubnub.subscribe({ channels: [currentChannel] });
    const listener = {
      message: (message) => {
        if (
          message.message.username &&
          message.message.visibleToast &&
          message.message.vidPath
        ) {
          setToastMessage(`${message.message.username} just logged in!`);
          setToastVisible(message.message.visibleToast);
          setVideoPath(() => message.message.vidPath);
        }
      },
      status: (statusEvent) => {
        if (statusEvent.category === "PNUnknownCategory") {
          console.error("PubNub Error Status:", statusEvent);
        }
      },
    };
    pubnub.addListener(listener);
    return () => {
      pubnub.removeListener(listener);
      pubnub.unsubscribeAll();
    };
  });

  useEffect(() => {
    if (videoPath) {
      setShowVideo(true);
    }
  }, [videoPath]);

  useEffect(() => {
    if (toastVisible) {
      const timeout = setTimeout(() => {
        setToastVisible(false);
      }, 8000);
      return () => clearTimeout(timeout);
    }
  }, [toastVisible]);

  return (
    <div
      className="flex w-full h-[800px] items-center justify-center bg-cover bg-no-repeat bg-center"
      style={{ backgroundImage: `url(${currentBackground})` }}
    >
      <BackgroundClickables
        onFrogClick={handleFrogClick}
        onCoinClick={handleCoinClick}
      />
      <Toast message={toastMessage} isVisible={toastVisible} />
      {showVideo && (
        <div className="absolute object-cover z-50 pointer-events-none">
          <video
            src={videoPath}
            autoPlay
            preload="auto"
            onError={(e) => {
              setShowVideo(false);
              setVideoPath("");
            }}
            onEnded={() => {
              setShowVideo(false);
              setVideoPath("");
            }}
          >
            <source src={videoPath} type='video/webm; codecs="vp8, vorbis"' />
          </video>
        </div>
      )}
      <div className="relative z-20">
        <div className="flex flex-col items-center justify-center gap-[24px] md:gap-[48px]">
          <div className="flex flex-col items-center justify-center">
            <p className="md:text-[16px] text-[12px]">
              Estimated 2024 Halving Date: April 20, 2024 (UTC)
            </p>
            <h1 className="lg:text-[49px] md:text-[39px] text-[25px] text-center">
              Bitcoin Halving Countdown
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center">
            <CountdownTimer date={date} />
          </div>
          <div className="flex flex-col md:flex-row md:gap-[12px] gap-[24px] w-fit items-center justify-center">
            <button
              className="flex bg-black text-white items-center justify-center rounded-[12px] py-[16px] px-[24px] gap-[12px]"
              onClick={tweet}
            >
              <Icon icon="pajamas:twitter" className="w-[24px] h-[24px]" />
              Share Event on Twitter
            </button>
            {!isConnected && (
              <button
                className="flex border-[2px] border-black gap-[4px] items-center justify-center px-[24px] py-[12px] rounded-[12px] shadow-[2px_2px_rgba(0,0,0,255)]"
                onClick={() => {
                  setDiscordChatEnabled(!discordChatEnabled);
                }}
              >
                Connect Discord
                <Icon
                  icon="teenyicons:top-right-outline"
                  className="w-[12px] h-[12px]"
                />
              </button>
            )}
          </div>
        </div>
      </div>
      {discordChatEnabled && (
        <div className="fixed lg:w-1/3 md:w-1/2 w-full h-full border-l-[3px] border-black right-0 top-0 bg-[#ADADAD] z-50">
          <WidgetBot
            server="1172865986026815508" // Change to required server.
            channel="1228178610381262931" // Switch to a specific channel where the guest can interact with server users.
            shard="https://emerald.widgetbot.io"
            className=" w-full h-full relative"
            onAPI={onAPI}
          />
          <div className="fixed top-[12px] right-[250px] z-50 items-center">
            <Icon
              icon="radix-icons:cross-2"
              className="w-[24px] h-[24px] text-black"
              onClick={() => {
                setDiscordChatEnabled(false);
                setApi(null);
              }}
            />
          </div>
        </div>
      )}
      {isConnected && !discordChatEnabled && (
        <button
          className="fixed bottom-0 right-0 border-t-[3px] border-l-[3px] border-black rounded-tl-[16px] w-2/3 bg-white md:w-1/2 lg:w-1/3 z-50"
          onClick={() => {
            setDiscordChatEnabled(!discordChatEnabled);
          }}
        >
          <Icon icon="logos:discord" className="w-[90px] h-[25px] m-[16px]" />
        </button>
      )}
    </div>
  );
};

export default Home;
