import { useState } from "react";
import WidgetBot from "@widgetbot/react-embed";

const BackgroundClickables = ({ onFrogClick, onCoinClick }) => {
  const coinImage1 = [
    "/images/coins/halving-coin-1a.png",
    "/images/coins/halving-coin-1b.png",
  ];
  const coinImage2 = [
    "/images/coins/halving-coin-2a.png",
    "/images/coins/halving-coin-2b.png",
  ];
  const coinImage3 = [
    "/images/coins/halving-coin-3a.png",
    "/images/coins/halving-coin-3b.png",
  ];
  const coinImage4 = [
    "/images/coins/halving-coin-4a.png",
    "/images/coins/halving-coin-4b.png",
  ];

  const ribbitImage = [
    "/images/frog/halving-frog-1.png",
    "/images/frog/halving-frog-2.png",
    "/images/frog/halving-frog-3.png",
    "/images/frog/halving-frog-4.png",
    "/images/frog/halving-frog-5.png",
  ];

  const [ribbit1, setRibbit1] = useState(0);
  const [ribbit2, setRibbit2] = useState(0);
  const [ribbit3, setRibbit3] = useState(0);
  const [coin1, setCoin1] = useState(0);
  const [coin2, setCoin2] = useState(0);
  const [coin3, setCoin3] = useState(0);
  const [coin4, setCoin4] = useState(0);
  const [fade1, setFade1] = useState(false);
  const [fade2, setFade2] = useState(false);
  const [fade3, setFade3] = useState(false);
  const [fade4, setFade4] = useState(false);

  const handleClick = (id) => {
    if (id === 1) {
      setFade1(true);
      setTimeout(() => setFade1(false), 3000);
    } else if (id === 2) {
      setFade2(true);
      setTimeout(() => setFade2(false), 3000);
    } else if (id === 3) {
      setFade3(true);
      setTimeout(() => setFade3(false), 3000);
    } else if (id === 4) {
      setFade4(true);
      setTimeout(() => setFade4(false), 3000);
    }
    onCoinClick();
  };

  return (
    <div className="w-full h-[800px] absolute z-10">
      {/* Frogs */}
      {ribbitImage && (
        <img
          src={ribbitImage[ribbit1]}
          className="absolute origin-top-right w-[50px] h-[50px] right-[50px] top-[4px] rotate-[15deg] md:w-[137px] md:h-[137px] md:top-[5px] md:right-[224px] md:rotate-[15deg] xl:top-[5px] xl:right-[384px] xl:w-[181px] xl:h-[181px] xl:rotate-[12deg]"
          alt="ribbit1"
          onClick={() => {
            if (ribbit1 < ribbitImage.length - 1) {
              setRibbit1(ribbit1 + 1);
            } else {
              setRibbit1(0);
            }
            onFrogClick();
          }}
        />
      )}
      {ribbitImage && (
        <img
          src={ribbitImage[ribbit2]}
          className="absolute origin-bottom-left w-[80px] h-[80px] rotate-[-10deg] top-[140px] md:w-[90px] md:h-[90px] md:left-[6px] md:top-[269px] xl:top-[250px] xl:left-[5px] xl:w-[181px] xl:h-[181px] xl:rotate-[-22deg]"
          alt="ribbit2"
          onClick={() => {
            if (ribbit2 < ribbitImage.length - 1) {
              setRibbit2(ribbit2 + 1);
            } else {
              setRibbit2(0);
            }
            onFrogClick();
          }}
        />
      )}
      {ribbitImage && (
        <img
          src={ribbitImage[ribbit3]}
          className="absolute w-[110px] h-[110px] bottom-[108px] left-[91px] md:left-[232px] md:bottom-[6px] xl:bottom-[5px] xl:left-[388px] xl:w-[207px] xl:h-[207px]"
          alt="ribbit3"
          onClick={() => {
            if (ribbit3 < ribbitImage.length - 1) {
              setRibbit3(ribbit3 + 1);
            } else {
              setRibbit3(0);
            }
            onFrogClick();
          }}
        />
      )}
      {/* Coins */}
      {coinImage1 && (
        <div
          className={`coins ${fade1 ? "fade-out" : ""}`}
          onClick={() => handleClick(1)}
        >
          <img
            src={coinImage1[coin1]}
            className="absolute w-[40px] h-[40px] left-[20px] top-[4px] md:w-[80px] md:h-[80px] md:top-0 md:left-[45px] xl:w-[100px] xl:h-[100px] xl:top-[10px] xl:left-[228px]"
            alt="coin1"
            onClick={() => {
              setCoin1(1);
              const timeout = setTimeout(() => {
                setCoin1(0);
              }, 3000);
            }}
          />
        </div>
      )}
      {coinImage2 && (
        <div
          className={`coins ${fade2 ? "fade-out" : ""}`}
          onClick={() => handleClick(2)}
        >
          <img
            src={coinImage2[coin2]}
            className="absolute w-[60px] h-[60px] left-[16px] bottom-[48px] md:w-[100px] md:h-[100px] md:left-[28px] md:bottom-[81px] xl:w-[160px] xl:h-[160px] xl:bottom-[77px] xl:left-[14px]"
            alt="coin2"
            onClick={() => {
              setCoin2(1);
              const timeout = setTimeout(() => {
                setCoin2(0);
              }, 3000);
            }}
          />
        </div>
      )}
      {coinImage3 && (
        <div
          className={`coins ${fade3 ? "fade-out" : ""}`}
          onClick={() => handleClick(3)}
        >
          <img
            src={coinImage3[coin3]}
            className="absolute origin-top-right w-[60px] h-[60px] right-0 top-[70px] rotate-[15deg] md:w-[100px] md:h-[100px] md:top-[28px] md:rotate-0 xl:w-[109px] xl:h-[109px] xl:bottom-[149px] xl:right-[51px]"
            alt="coin3"
            onClick={() => {
              setCoin3(1);
              const timeout = setTimeout(() => {
                setCoin3(0);
              }, 3000);
            }}
          />
        </div>
      )}
      {coinImage4 && (
        <div
          className={`coins ${fade4 ? "fade-out" : ""}`}
          onClick={() => handleClick(4)}
        >
          <img
            src={coinImage4[coin4]}
            className="absolute w-[44px] h-[44px] right-[15px] bottom-[141px] md:w-[70px] md:h-[70px] md:right-[12px] md:bottom-[159px] xl:w-[100px] xl:h-[100px] xl:bottom-[149px] xl:right-[51px]"
            alt="coin4"
            onClick={() => {
              setCoin4(1);
              const timeout = setTimeout(() => {
                setCoin4(0);
              }, 3000);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BackgroundClickables;
