import { useState, useEffect } from "react";

const CountdownTimer = ({ date }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const countdownDate = new Date(date).getTime();
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(interval);
      } else {
        setTimeRemaining({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [date]);

  return (
    <div className="flex gap-[8px] md:gap-[32px]">
      <div className="flex flex-col border-[2px] border-black bg-[#FFD198] rounded-[10px] shadow-[2px_2px_rgba(0,0,0,255)] items-center justify-center w-[70px] h-[70px] md:border-[3px] md:w-[100px] md:h-[100px] md:rounded-[24px] xl:w-[180px] xl:h-[180px]">
        <h1 className="xl:text-[49px] md:text-[39px] text-[20px]">
          {timeRemaining.days}
        </h1>
        <p className="xl:text-[25px] md:text-[20px] text-[16px]">Days</p>
      </div>
      <div className="flex flex-col border-[2px] border-black bg-[#FFD198] rounded-[10px] shadow-[2px_2px_rgba(0,0,0,255)] items-center justify-center w-[70px] h-[70px] md:border-[3px] md:w-[100px] md:h-[100px] md:rounded-[24px] xl:w-[180px] xl:h-[180px]">
        <h1 className="xl:text-[49px] md:text-[39px] text-[20px]">
          {timeRemaining.hours}
        </h1>
        <p className="xl:text-[25px] md:text-[20px] text-[16px]">Hours</p>
      </div>
      <div className="flex flex-col border-[2px] border-black bg-[#FFD198] rounded-[10px] shadow-[2px_2px_rgba(0,0,0,255)] items-center justify-center w-[70px] h-[70px] md:border-[3px] md:w-[100px] md:h-[100px] md:rounded-[24px] xl:w-[180px] xl:h-[180px]">
        <h1 className="xl:text-[49px] md:text-[39px] text-[20px]">
          {timeRemaining.minutes}
        </h1>
        <p className="xl:text-[25px] md:text-[20px] text-[16px]">Minutes</p>
      </div>
      <div className="flex flex-col border-[2px] border-black bg-[#FFD198] rounded-[10px] shadow-[2px_2px_rgba(0,0,0,255)] items-center justify-center w-[70px] h-[70px] md:border-[3px] md:w-[100px] md:h-[100px] md:rounded-[24px] xl:w-[180px] xl:h-[180px]">
        <h1 className="xl:text-[49px] md:text-[39px] text-[20px]">
          {timeRemaining.seconds}
        </h1>
        <p className="xl:text-[25px] md:text-[20px] text-[16px]">Seconds</p>
      </div>
    </div>
  );
};

export default CountdownTimer;
